<template>
  <div
    class="tab-pane fade"
    id="view-setting"
    role="tabpanel"
    aria-labelledby="view-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Görsel Ayarlar</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />
      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Width -->
          <WeInput
            label="Slider En"
            v-model="data.slider_width"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Width -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Height -->
          <WeInput
            label="Slider Boy"
            v-model="data.slider_height"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Height -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Width -->
          <WeInput
            label="Thumbnail En"
            v-model="data.thumb_width"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Width -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Height -->
          <WeInput
            label="Thumbnail Boy"
            v-model="data.thumb_height"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Height -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Width -->
          <WeInput
            label="Medium En"
            v-model="data.medium_width"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Width -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Height -->
          <WeInput
            label="Medium Boy"
            v-model="data.medium_height"
            v-bind:read-only="true"
            v-bind:filter-number="true"
          />
          <!-- Height -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Width -->
          <WeInput
            label="Logo En"
            v-model="data.logo_width"
            v-bind:filter-number="true"
          />
          <!-- Width -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Height -->
          <WeInput
            label="Logo Boy"
            v-model="data.logo_height"
            v-bind:filter-number="true"
          />
          <!-- Height -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "ViewSetting",
  data() {
    return {
      data: {
        slider_width: this.settings["view.slider_width"],
        slider_height: this.settings["view.slider_height"],
        thumb_width: this.settings["view.thumb_width"],
        thumb_height: this.settings["view.thumb_height"],
        medium_width: this.settings["view.medium_width"],
        medium_height: this.settings["view.medium_height"],
        logo_width: this.settings["view.logo_width"],
        logo_height: this.settings["view.logo_height"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      this.settings["view.slider_width"] = this.data.slider_width;
      this.settings["view.slider_height"] = this.data.slider_height;
      this.settings["view.thumb_width"] = this.data.thumb_width;
      this.settings["view.thumb_height"] = this.data.thumb_height;
      this.settings["view.medium_width"] = this.data.medium_width;
      this.settings["view.medium_height"] = this.data.medium_height;
      this.settings["view.logo_width"] = this.data.logo_width;
      this.settings["view.logo_height"] = this.data.logo_height;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
